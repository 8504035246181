import React, { useContext } from 'react';

import ContentBlock from 'components/content-block';
import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';

import './what-are-nsaids.scss';

const pageTitle = 'What Are NSAIDs';
const pageDescription = '';

const WhatAreNsaids = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout
      indication={siteIndications.patient}
      className="patient-what-are-nsaids"
    >
      <Seo indication={siteIndications.patient} pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <h2 className="blue-headline no-upper-case">
              WHAT ARE <strong>NSAIDs?</strong>
            </h2>
            <p className="copy-block">
              NSAIDs (nonsteroidal anti-inflammatory drugs) are a group of
              medicines used to treat pain and redness, swelling, and heat
              (inflammation) from medical conditions such as different types of
              arthritis, menstrual cramps, and other types of short-term pain.
            </p>
            <h2 className="blue-headline no-upper-case">
              HOW DO <strong>NSAIDs</strong> WORK?
            </h2>
            <p className="copy-block">
              NSAIDs reduce the body’s production of prostaglandins, chemicals
              that promote inflammation and pain.
            </p>
            <h2 className="blue-headline">
              What is{' '}
              <strong>SPRIX® (ketorolac tromethamine) Nasal spray?</strong>{' '}
            </h2>
            <p className="copy-block">
              SPRIX Nasal Spray is the first and only NSAID (nonsteroidal
              anti-inflammatory drug) nasal spray that contains the potent pain
              reliever, ketorolac. Since 2010, SPRIX Nasal Spray has been
              approved for the short-term treatment of moderate to moderately
              severe pain for up to 5 days in adult patients who require
              opioid-strength pain relief.
            </p>
          </ContentBlock>
        </div>
      </div>
    </Layout>
  );
};

export default WhatAreNsaids;
